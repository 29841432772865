/* -------------------------------------------------------------------------------- */
/*  =Imports
/* -------------------------------------------------------------------------------- */

/* BassCSS */
@import 'normalize.css';
/*@import 'basscss-btn';
@import 'basscss-btn-outline';
@import 'basscss-btn-primary';
@import 'basscss-btn-sizes';*/
/*@import 'basscss-background-images';*/
/*@import 'basscss-background-colors';*/
/*@import 'basscss-colors';*/
/*@import 'basscss';*/
/*@import 'basscss-responsive-layout';*/
/*@import 'basscss-responsive-margin';*/
/*@import 'basscss-responsive-padding';*/
/*@import 'basscss-responsive-position';*/
/*@import 'basscss-responsive-type-scale';*/
@import '_custom-responsive-type-scale';
/*@import 'basscss-responsive-typography';*/

/* Variables */
@import '_vars';

/* Fonts */
@import '_font-league-spartan';

/* Sprites */
@import '_sprites';
/*@import '_sprites-images';*/

/* Flag object */
/*@import '_flag';*/


/* -------------------------------------------------------------------------------- */
/*  =General
/* -------------------------------------------------------------------------------- */

html {
  height: 100%;
}
body {
  margin: 0;
  font-size: 100%;
  height: 100%;
  font-family: var(--main-font-family);
  line-height: 1.8;
  color: #fff;
  transition: transform .4s cubic-bezier(0, 0, 0.2, 1);
  background: #0d1a66 url('../../images/bg-1.jpg') 50% 50% no-repeat;
  background-size: cover;
}
h1, h2, h3, h4, h5, h6 {
  font-family: var(--heading-font-family);
}

::-moz-selection { background: rgba(16, 26, 103, 0.2); }
::selection { background: rgba(16, 26, 103, 0.2); }

a {
  color: #fff;
  transition: opacity .4s;
}
a:link,
a:visited {
  text-decoration: none;
}
a:hover   { opacity: 0.7; }
a:active  {
  /*transition: color .3s;*/
}
b, strong {
  font-weight: 700;
}



/* -------------------------------------------------------------------------------- */
/*  =Icons and SVGs
/* -------------------------------------------------------------------------------- */

[class^="icon--"] {
  fill: currentColor;
  transition: fill 0.4s;
  vertical-align: middle;
}
a:hover [class^="icon--"] {
  fill: currentColor;
}
/*.svg--icon-menu { fill: var(--orange); }*/

.wrap {
  width: 79%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  padding-left: 1rem;
  box-sizing: border-box;
}
@media (min-width: 480px) {
  .wrap {
    width: 55%;
    padding-left: 2rem;
  }
}

.main-title,
.subtitle {
  margin: 0;
  line-height: 1.5;
}
.technologies {
  font-weight: 300;
/*  text-transform: uppercase;
  letter-spacing: 0.15em;*/
  /*font-family: var(--heading-font-family);*/
}
.social-links a {
  display: inline-block;
  margin-left: 1rem;
}
.technologies,
.social-links {
  margin: 0.4rem 0;
}
@media (min-width: 360px) and (min-height: 480px) {
  .technologies,
  .social-links {
    margin: 1rem 0;
  }
}
