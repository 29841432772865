.icon--dribbble {
	width: 24px;
	height: 28px;
}

.icon--email {
	width: 28px;
	height: 28px;
}

.icon--github {
	width: 24px;
	height: 28px;
}

.icon--linkedin {
	width: 24px;
	height: 28px;
}

.icon--pinterest {
	width: 21px;
	height: 26px;
}

.icon--twitter {
	width: 26px;
	height: 28px;
}

